import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { uouPayButtonClick } from '@wix/bi-logger-payments-data/v2';
import { PayAppBIParams, MessageFromPopup } from '../../types';
import { OrderService } from '../../services/api/Order';
import { PremiumStatusService } from '../../services/api/PremiumStatus';
import { APP_ID, MODAL_SIZE, buttonRole } from '../../utils/constants';
import {
  getNoPaymentsPopupUrl,
  getUpgradeAndTestPaymentModel,
} from '../../utils/links';
import { isPreview } from '../../utils/states';
import { ViewerInteractionName } from '../../enums/Interaction';
import { getBiLogger } from '../../utils/biLogger';
import { Experiment } from '../../enums/Experiment';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  const reportError = (error: unknown) => {
    console.error('[pay-button]:', error);
    flowAPI.reportError(error as Error);
  };

  return {
    async pageReady() {
      const {
        httpClient,
        errorHandler,
        experiments,
        translations,
        controllerConfig: { wixCodeApi, platformAPIs, appParams },
        fedops,
      } = flowAPI;

      const webBiLogger = platformAPIs.biLoggerFactory?.();
      const biLogger = getBiLogger(
        {
          appid: APP_ID,
          appInstanceId: appParams.appInstanceId,
          memberId: platformAPIs.bi?.siteMemberId,
          msid: platformAPIs.bi?.metaSiteId,
          origin: wixCodeApi.location.url,
          paybuttonid: appParams.instanceId,
          sessionId: platformAPIs.bi?.viewerSessionId,
          visitorId: platformAPIs.bi?.visitorId,
        },
        webBiLogger!,
      );

      const instance = wixCodeApi.site.getAppToken?.(APP_ID) as string;
      const { productId, termsAndConditionsLink } =
        controllerConfig.config as any;
      const button = controllerConfig.$w(`@${buttonRole}`);
      const paymentOptions = termsAndConditionsLink
        ? { termsAndConditionsLink }
        : {};
      const { viewMode, formFactor: deviceType } = wixCodeApi.window;

      const payAppBiParams: PayAppBIParams = {
        sessionId: platformAPIs.bi?.viewerSessionId ?? '',
        pbId: appParams.instanceId,
        pbOrigin: wixCodeApi.location.url,
        siteMemberId: platformAPIs.bi?.siteMemberId ?? '',
      };
      const orderService = new OrderService({
        instance,
        httpClient,
        errorHandler,
        experiments,
        translate: translations.t,
      });
      const premiumStatusService = new PremiumStatusService({
        instance,
        httpClient,
        errorHandler,
        experiments,
        translate: translations.t,
      });

      const createOrder = async () => {
        try {
          const { orderId } = await orderService.create(productId);

          return orderId;
        } catch (error) {
          if (experiments.enabled(Experiment.EnableErrorHandler)) {
            const resolvedError = errorHandler.getResolvedError(error);

            await openNoPaymentsPopup(
              translations.t('payButton.noPaymentsPopup.commonError.title'),
              resolvedError.message,
            );
          }

          reportError(error);
        }
      };

      const startPayment = async () => {
        const orderId = await createOrder();

        // @ts-expect-error
        return wixCodeApi.pay.startPayment(orderId, {
          ...paymentOptions,
          ...payAppBiParams,
        });
      };

      const openUpgradeAndPlaceTestModal = async () => {
        const event = await wixCodeApi.window.openModal(
          getUpgradeAndTestPaymentModel(instance),
          {
            width: MODAL_SIZE[deviceType].width,
            height: MODAL_SIZE[deviceType].height,
            theme: 'BARE',
          },
        );

        if (event && event.message === MessageFromPopup.PlaceTestOrder) {
          await startPayment();
        }
      };

      const openNoPaymentsPopup = async (title?: string, message?: string) => {
        await wixCodeApi.window.openModal(
          getNoPaymentsPopupUrl({
            instance,
            title,
            contentText: message,
            referralInfo: 'payButton',
            startLoadTime: Date.now(),
          }),
          {
            width: MODAL_SIZE[deviceType].width,
            height: MODAL_SIZE[deviceType].height,
            theme: 'BARE',
          },
        );
      };

      const onButtonClick = async () => {
        biLogger.report(uouPayButtonClick({}));

        const isPremium = await premiumStatusService.getIsPremium();

        if (isPremium) {
          fedops.interactionStarted(
            ViewerInteractionName.PAY_BUTTON_OPEN_PAY_APP,
          );

          return startPayment();
        }

        if (isPreview(viewMode)) {
          fedops.interactionStarted(ViewerInteractionName.OPEN_PREMIUM_POPUP);
          return openUpgradeAndPlaceTestModal();
        }

        fedops.interactionStarted(ViewerInteractionName.OPEN_NO_PAYMENTS_POPUP);
        return openNoPaymentsPopup();
      };

      button.onClick(async () => {
        try {
          await onButtonClick();
        } catch (error) {
          reportError(error);
        }
      });
    },
  };
};

export default createController;
