import type { CreateOrderResponse } from '@wix/ambassador-payment-paybutton-web/http';
import { BaseHttpService } from '../BaseHttpService';
import { ORDER_API_PATH } from './constants';

export class OrderService extends BaseHttpService {
  async create(productId: string) {
    const { data } = await this.withErrorHandler(
      () =>
        this.httpClient.post<CreateOrderResponse>(
          ORDER_API_PATH,
          { productId },
          this.options,
        ),
      {
        statusCodeError: {
          INVALID_ARGUMENT: () => ({
            message: this.translate(
              'payButton.noPaymentsPopup.validationError.invalidArgument',
            ),
          }),
          UNAUTHENTICATED: () => ({
            message: this.translate(
              'payButton.noPaymentsPopup.validationError.unauthenticated',
            ),
          }),
          PERMISSION_DENIED: () => ({
            message: this.translate(
              'payButton.noPaymentsPopup.validationError.permissionDenied',
            ),
          }),
        },
      },
    );

    return data;
  }
}
