import { POP_UP_LINK, NO_PAYMENTS_POP_UP_LINK } from './constants';

export const buildQuery = <Params extends object>(params: Params): string =>
  Object.entries(params)
    .reduce<string[]>((query, [key, value]) => {
      if (value !== undefined) {
        query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return query;
    }, [])
    .join('&');

export const getUpgradeAndTestPaymentModel = (instance: string): string =>
  `${POP_UP_LINK}?${buildQuery({
    instance,
    referralInfo: 'payApiPaymentsPopup',
    startLoadTime: Date.now(),
  })}`;

export const getNoPaymentsPopupUrl = (params: {
  instance: string;
  referralInfo?: string;
  startLoadTime?: number;
  title?: string;
  contentText?: string;
}): string => `${NO_PAYMENTS_POP_UP_LINK}?${buildQuery(params)}`;
