import type {
  IHttpClient,
  Experiments,
  ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import merge from 'lodash/merge';
import type { ErrorHandlerPublicAPI, TypedErrorCodesMap } from '../../types';
import { Experiment } from '../../enums/Experiment';

type TFunction = ControllerFlowAPI['translations']['t'];

type BaseHttpServiceConfig = {
  instance: string;
  httpClient: IHttpClient;
  errorHandler: ErrorHandlerPublicAPI;
  experiments: Experiments;
  translate: TFunction;
};

type RequestOptions = {
  headers: { Authorization: string };
};

export class BaseHttpService {
  private readonly errorHandler: ErrorHandlerPublicAPI;
  protected readonly experiments: Experiments;
  protected readonly httpClient: IHttpClient;
  protected readonly translate: TFunction;
  protected readonly options: RequestOptions = {
    headers: { Authorization: '' },
  };
  protected errorCodesMap: TypedErrorCodesMap<unknown> = {};

  constructor(config: BaseHttpServiceConfig) {
    this.httpClient = config.httpClient;
    this.errorHandler = config.errorHandler;
    this.experiments = config.experiments;
    this.translate = config.translate;
    this.options.headers.Authorization = config.instance;
  }

  protected withErrorHandler<T>(
    requestFn: () => Promise<T>,
    errorCodesMapOverride?: TypedErrorCodesMap<T>,
  ): Promise<T> {
    if (!this.experiments.enabled(Experiment.EnableErrorHandler)) {
      return requestFn();
    }

    const errorCodesMap = merge({}, this.errorCodesMap, errorCodesMapOverride);

    return this.errorHandler.withErrorHandler(requestFn, {
      errorCodesMap,
    });
  }
}
